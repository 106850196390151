/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Link from '../Link';

import { breakpoint } from '../../theme';

const Filter = styled.div`
  width: 100%;
`;

const FilterItem = styled.button`
  display: inline-block;
  cursor: pointer;
  padding: ${rem(9)} ${rem(17)};
  appearance: none;
  user-select: none;
  text-align: center;
  font-size: ${rem(13)};
  font-family: ${(props) => props.theme.typography.fonts.button};
  font-weight: ${(props) => props.theme.typography.button.fontWeight};
  text-transform: ${(props) => props.theme.typography.button.textTransform};
  letter-spacing: ${(props) => props.theme.typography.button.letterSpacing};
  border-radius: 999px;
  transition: ${(props) =>
    `all ${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`};
  border: 1px solid ${(props) => props.theme.palette.colors.primary};
  color: ${(props) => props.theme.palette.text.primary};
  background-color: transparent;
  margin: ${rem(6)} ${rem(14)} ${rem(10)} 0;

  /* cubic-bezier(0.78, 0.14, 0.15, 0.86) */

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(14.5)};
  }

  &.active,
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.primary};
    color: ${(props) => props.theme.palette.text.negative};
  }

  &.active {
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
  }
`;

function TagFilter({ items, onClick, active, showAll }) {
  const { t } = useTranslation();

  let filterItems = [];

  if (showAll) {
    filterItems.push({
      id: 0,
      key: 'filter-all',
      title: t('filterAll'),
    });
  }

  filterItems = [...filterItems, ...items];

  return (
    <Filter>
      {filterItems?.map((item) => {
        return (
          <FilterItem
            as={typeof onClick === 'undefined' && Link}
            to={item?.url}
            key={item.key}
            onClick={() => onClick && onClick(item.id)}
            className={active === item.id && 'active'}
          >
            {item.title}
          </FilterItem>
        );
      })}
    </Filter>
  );
}

TagFilter.propTypes = {
  onClick: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      url: PropTypes.string,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  active: PropTypes.number,
  showAll: PropTypes.bool,
};

TagFilter.defaultProps = {
  onClick: undefined,
  active: 0,
  showAll: true,
};

export default TagFilter;
