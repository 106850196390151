import { graphql } from 'gatsby';

export const Fragment = graphql`
  fragment PageContent on WpPage {
    id
    template {
      __typename
      templateName
    }
    featuredImage {
      node {
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 75
              transformOptions: { cropFocus: ENTROPY }
              layout: FULL_WIDTH
            )
          }
        }
        sourceUrl
        caption
        altText
        title
        id
        description
      }
    }
    content
    title
    slug
    uri
    seo {
      breadcrumbs {
        text
        url
      }
      canonical
      cornerstone
      focuskw
      metaDesc
      metaKeywords
      metaRobotsNofollow
      metaRobotsNoindex
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      schema {
        articleType
        pageType
      }
      title
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
    }
    locale {
      id
      locale
    }
    lang
    isPageOnFront
    translated {
      id
      uri
      title
      lang
      locale {
        id
        locale
      }
    }
    hero {
      backgroundvideo {
        localFile {
          url
        }
      }
      backgroundvideoposter {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 75, layout: FULL_WIDTH)
          }
        }
        sourceUrl
        caption
        altText
        title
        id
        description
      }
      carousel {
        ... on WpPage_Hero_Carousel_SlideDefault {
          __typename
          button {
            link
            label: text
          }
          image {
            id
          }
        }
      }
    }
    pageOptions {
      heroSize
    }
    meta {
      subTitle
    }
    sections {
      fieldGroupName
      sections {
        ... on WpPage_Sections_Sections_Gallery {
          __typename
          title
          gallery {
            id
            altText
            caption
            link
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 75
                  transformOptions: { cropFocus: ENTROPY }
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        ... on WpPage_Sections_Sections_Contacts {
          __typename
          title
          contacts {
            ... on WpStaff {
              id
              title
            }
          }
        }
        ... on WpPage_Sections_Sections_Video {
          __typename
          embedCode
          title
        }
        ... on WpPage_Sections_Sections_Teasers {
          __typename
          title
          teasers {
            ... on WpTeaser {
              id
              title
            }
          }
        }
        ... on WpPage_Sections_Sections_Imagebgsection {
          __typename
          title
          content
          image {
            id
            altText
            caption
            localFile {
              id
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 75
                  transformOptions: { cropFocus: ENTROPY }
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        ... on WpPage_Sections_Sections_Contactform7 {
          __typename
          form
          title
        }
        ... on WpPage_Sections_Sections_Columns {
          __typename
          title
          backgroundColor
          centeredColumn
          content
          withIntro
          columns {
            content
          }
        }
        ... on WpPage_Sections_Sections_Splitsection {
          __typename
          title
          reverse
          content
          button {
            link
            label: text
          }
          image {
            id
            altText
            caption
            localFile {
              id
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 75
                  transformOptions: { cropFocus: ENTROPY }
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        ... on WpPage_Sections_Sections_Blurbsection {
          __typename
          title
          content
          backgroundColor
          blurbs {
            ... on WpPage_Sections_Sections_Blurbsection_Blurbs_BlurbDefault {
              fieldGroupName
              content {
                subText
                title
              }
              button {
                link
                text
              }
              image {
                id
                altText
                caption
                localFile {
                  id
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      height: 560
                      quality: 75
                      transformOptions: { cropFocus: ENTROPY }
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
        ... on WpPage_Sections_Sections_Packages {
          __typename
          title
          backgroundColor
          packages {
            ... on WpPackage {
              ...PackageItemContent
            }
          }
        }
        ... on WpPage_Sections_Sections_Packagearchive {
          __typename
          title
          backgroundColor
          packagecategories {
            id
            name
            uri
            termTaxonomyId
            count
            packages {
              nodes {
                ...PackageItemContent
              }
            }
          }
        }
        ... on WpPage_Sections_Sections_Sidebar {
          __typename
          title
          content
          sidebar
        }
      }
    }
  }
`;

export default Fragment;
