import { graphql } from 'gatsby';

export const Fragment = graphql`
  fragment SiteInformation on Site {
    id
    siteMetadata {
      title
      description
      siteUrl
    }
  }
`;

export default Fragment;
