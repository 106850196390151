import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

function Image({ image, fallback, alt, ...other }) {
  /* eslint-disable react/jsx-props-no-spreading */
  if (image) {
    return <GatsbyImage image={image} alt={alt || fallback.alt} {...other} />;
  }

  return <img src={fallback.url} alt={fallback.alt || alt} {...other} />;
}

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fallback: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  alt: PropTypes.string,
};

Image.defaultProps = {
  image: null,
  fallback: {},
  alt: '',
};

export default Image;
