import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { getPathPrefix } from '../utils/i18n';

function useLangRedirect(object) {
  const { i18n } = useTranslation();

  const { lang } = object;

  if (typeof window === 'undefined' || process.env.GATSBY_IS_PREVIEW) {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }

    return;
  }

  useEffect(() => {
    const pathPrefix = getPathPrefix(i18n.language);

    if (i18n.language !== lang) {
      if (object.isPageOnFront) {
        navigate(pathPrefix, { replace: true });

        return;
      }

      const translation = object.translated?.find?.(
        ({ lang: postLang }) => postLang === i18n.language,
      );

      if (translation) {
        navigate(translation.uri, { replace: true });
      } else {
        navigate(pathPrefix, { replace: true });
      }
    }
  });
}

export default useLangRedirect;
