/* eslint-disable no-underscore-dangle */
import { useLocation } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Facebook from './Facebook';
import Twitter from './Twitter';

const query = graphql`
  query SEO {
    wp {
      seo {
        webmaster {
          googleVerify
          yandexVerify
          msVerify
          baiduVerify
        }
        schema {
          siteName
          wordpressSiteName
          siteUrl
          inLanguage
          companyName
          companyOrPerson
          companyLogo {
            mediaItemUrl
          }
          logo {
            mediaItemUrl
          }
          personLogo {
            mediaItemUrl
          }
        }
        breadcrumbs {
          showBlogPage
          separator
          searchPrefix
          prefix
          homeText
          enabled
          boldLast
          archivePrefix
          notFoundText
        }
        social {
          facebook {
            url
            defaultImage {
              mediaItemUrl
            }
          }
          instagram {
            url
          }
          linkedIn {
            url
          }
          mySpace {
            url
          }
          pinterest {
            url
            metaTag
          }
          twitter {
            cardType
            username
          }
          wikipedia {
            url
          }
          youTube {
            url
          }
        }
        openGraph {
          frontPage {
            title
            description
            image {
              altText
              sourceUrl
              mediaItemUrl
            }
          }
          defaultImage {
            altText
            sourceUrl
            mediaItemUrl
          }
        }
        contentTypes {
          post {
            title
            schemaType
            metaRobotsNoindex
            metaDesc
          }
          page {
            metaDesc
            metaRobotsNoindex
            schemaType
            title
          }
        }
        redirects {
          origin
          target
          format
          type
        }
      }
    }
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
        socialLinks {
          facebook
          instagram
          linkedin
          pinterest
          twitter
          vimeo
          youtube
        }
        schemaData {
          orgType
          telephone
          address {
            addressCountry
            addressLocality
            postalCode
            streetAddress
          }
          geo {
            latitude
            longitude
          }
        }
      }
    }
    logo: file(relativePath: { eq: "logo/logo.svg" }) {
      publicURL
    }
  }
`;

const SEO = ({
  title,
  description,
  lang,
  image,
  pathname,
  article,
  page,
  faq,
  classes,
}) => {
  const { pathname: routerPathname } = useLocation();

  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({ site, logo, wp }) => {
        const {
          siteMetadata: {
            // defaultTitle,
            defaultDescription,
            siteUrl,
            // siteLanguage,
            // ogLanguage,
            author,
            twitter,
            facebook,
            socialLinks,
            schemaData: siteSchemaData,
          },
        } = site;

        const {
          siteName: defaultTitle,
          // wordpressSiteName,
          // siteUrl: WPsiteUrl,
          inLanguage: siteLanguage,
          companyName,
          // companyOrPerson,
          // companyLogo: {
          //     mediaItemUrl
          // },
          // logo: {
          //     mediaItemUrl
          // },
          // personLogo: {
          //     mediaItemUrl
          // }
        } = wp.seo.schema;

        const seo = {
          title,
          description: description || defaultDescription,
          image: image ? `${siteUrl}${image}` : null,
          url: `${siteUrl}${pathname || routerPathname}`,
          lang: lang || siteLanguage || 'en',
        };

        const schemas = {
          organization: {
            '@type': siteSchemaData?.orgType || 'Organization',
            '@id': `${siteUrl}/#organization`,
            name: companyName || author,
            url: `${siteUrl}/`,
            image: `${siteUrl}${logo.publicURL}`,
            logo: {
              '@type': 'ImageObject',
              '@id': `${siteUrl}/#logo`,
              url: `${siteUrl}${logo.publicURL}`,
              caption: author,
            },
            sameAs: Object.values(socialLinks).filter(Boolean),
            ...(siteSchemaData?.telephone && {
              telephone: siteSchemaData.telephone,
            }),
            ...(siteSchemaData?.address && {
              address: {
                '@type': 'PostalAddress',
                ...siteSchemaData.address,
              },
            }),
            ...(siteSchemaData?.geo && {
              geo: {
                '@type': 'GeoCoordinates',
                ...siteSchemaData.geo,
              },
            }),
          },
        };

        schemas.website = {
          '@type': 'WebSite',
          '@id': `${siteUrl}/#website`,
          url: `${siteUrl}/`,
          name: defaultTitle,
          description: defaultDescription,
          inLanguage: seo.lang,
          copyrightHolder: { '@id': schemas.organization['@id'] },
        };

        if (seo.image) {
          schemas.image = {
            '@type': 'ImageObject',
            '@id': `${seo.url}#primaryimage`,
            inLanguage: seo.lang,
            url: seo.image,
          };
        }

        schemas.webpage = {
          '@type': 'WebPage',
          '@id': `${seo.url}#webpage`,
          url: seo.url,
          isPartOf: { '@id': schemas.website['@id'] },
          inLanguage: seo.lang,
          ...(title && { name: title }),
          ...(description && { description }),
          ...(schemas.image && {
            primaryImageOfPage: { '@id': schemas.image['@id'] },
          }),
        };

        if (article) {
          schemas.article = {
            '@type': 'Article',
            '@id': `${seo.url}#article`,
            url: seo.url,
            isPartOf: { '@id': schemas.webpage['@id'] },
            mainEntityOfPage: { '@id': schemas.webpage['@id'] },
            author: { '@id': schemas.organization['@id'] },
            publisher: { '@id': schemas.organization['@id'] },
            inLanguage: seo.lang,
            ...(title && { headline: title }),
            ...(description && { description }),
            ...(schemas.image && { image: { '@id': schemas.image['@id'] } }),
            ...(page?.date && {
              datePublished: page?.date,
            }),
            ...(page?.modified && {
              dateModified: page?.modified,
            }),
          };
        }

        if (faq) {
          schemas.faq = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            '@id': `${seo.url}#faqpage`,
            mainEntity: faq.map((item) => ({
              '@type': 'Question',
              name: item.name,
              acceptedAnswer: {
                '@type': 'Answer',
                text: item.text,
              },
            })),
          };
        }

        return (
          <>
            <Helmet
              title={seo.title}
              // titleTemplate={`%s — ${defaultTitle}`}
              // titleTemplate="%s"
              defaultTitle={defaultTitle}
              htmlAttributes={{
                lang: seo.lang,
                // itemScope: undefined,
                // itemType: `http://schema.org/${schema}`, // Should this be added to body tag
                // hrefLang: `what to add here`, // Should this be added to body tag
              }}
              bodyAttributes={
                classes && {
                  class: classes.join(' '),
                }
              }
            >
              <meta name="description" content={seo.description} />
              <meta name="image" content={seo.image} />
              <script type="application/ld+json">
                {JSON.stringify([
                  {
                    '@context': 'https://schema.org',
                    '@graph': Object.values(schemas),
                  },
                ])}
              </script>
            </Helmet>
            <Facebook
              desc={seo.description}
              image={seo.image}
              title={seo.title}
              type={article ? 'article' : 'website'}
              url={seo.url}
              locale={lang || seo.lang}
              name={facebook}
            />
            <Twitter
              title={seo.title}
              image={seo.image}
              desc={seo.description}
              username={twitter}
            />
          </>
        );
      }}
    </StaticQuery>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  faq: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  page: PropTypes.oneOfType([PropTypes.object]),
  lang: PropTypes.string,
  classes: PropTypes.arrayOf(PropTypes.string),
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  faq: null,
  page: null,
  lang: '',
  classes: null,
};
