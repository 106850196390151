import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { getImage, getSrc } from 'gatsby-plugin-image';

import styled from 'styled-components';

import Image from '../Image';
import Link from '../Link';
import { DiscreteItem as ReadMore } from '../Menu';

import { breakpoint } from '../../theme';

const Thumbnail = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
`;

const Inner = styled.div`
  display: block;
  width: 100%;
  text-align: left;
`;

const ThumbnailPlaceholder = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 66%;
  background-color: ${(props) => props.theme.palette.colors.grey};
`;

const Content = styled.div`
  position: relative;
  display: block;
  padding: ${(props) => props.theme.spacing.gutter} 0 ${rem(40)};
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${rem(16)};

  p {
    line-height: 1.55;
    margin-bottom: ${rem(14)};
  }
`;

const Title = styled.h3`
  display: block;
  width: 100%;
  margin-bottom: ${rem(7)};
  font-size: ${rem(26)};
  color: ${(props) => props.theme.palette.colors.primary};

  /* ${breakpoint('large')} {
    font-size: ${rem(20)};
  } */
`;

const Root = styled.div`
  width: 100%;
  transition: ${(props) =>
    `transform ${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`};
`;

function PackageItem(props) {
  const { image, title, text, button } = props;

  const itemImage = getImage(image?.localFile);
  const src = getSrc(image?.localFile);

  return (
    <Root>
      <Inner to={button?.link} as={button ? Link : null}>
        {itemImage ? (
          <Thumbnail>
            <Image
              image={itemImage}
              fallback={{
                url: src,
                alt: image.altText,
              }}
              alt={image.altText}
            />
          </Thumbnail>
        ) : (
          <ThumbnailPlaceholder />
        )}

        <Content>
          <Title>{title}</Title>

          {text && (
            <div
              dangerouslySetInnerHTML={{ __html: text }} // eslint-disable-line react/no-danger
            />
          )}

          {button?.text && <ReadMore as="span">{button.text}</ReadMore>}
        </Content>
      </Inner>
    </Root>
  );
}

PackageItem.propTypes = {
  title: PropTypes.node.isRequired,
  image: PropTypes.shape({
    localFile: PropTypes.oneOfType([PropTypes.object]),
    source_url: PropTypes.string,
    altText: PropTypes.string,
    title: PropTypes.string,
  }),
  text: PropTypes.string,
  button: PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.node,
  }).isRequired,
};

PackageItem.defaultProps = {
  image: null,
  text: '',
};

export default PackageItem;
